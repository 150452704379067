/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import "~animate.css/animate.css";
@font-face {
  font-family: "OpenSans";
  src: url("assets/font/Opensans/OpenSans-Regular.ttf");
}
@font-face {
  font-family: "OpenSans-bold";
  src: url("assets/font/Opensans/OpenSans-Bold.ttf");
}
@font-face {
  font-family: "OpenSans-black";
  src: url("assets/font/Opensans/OpenSans-ExtraBold.ttf");
}
:root {
  --ion-font-family: OpenSans;
}
.radio_list {
  .item-native {
    min-height: 30px;
  }
}
.modal-fullscreen {
  .modal-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
  }
}
.custom_loading {
  .loading-wrapper {
    background: transparent;
    box-shadow: none;
  }
  ion-spinner {
    display: none !important;
  }
}

.keyboard-btn:hover {
  background-color: #3498db !important;
  color: white !important;
  border: 1px solid white !important;
}
.ok-btn:hover {
  background-color: #3498db !important;
  color: white !important;
  border: 1px solid white !important;
}
